export default {
    state: {
        selectObject: null
    },
    mutations: {
        flashGameSetSelect(state, obj){
            let {flashGame} = state
            flashGame = flashGame || {}
            flashGame.selectObject = obj
            state.flashGame = flashGame
        },
        flashGameClearSelect(state){
            let {flashGame} = state
            flashGame = flashGame || {}
            flashGame.selectObject = null
            state.flashGame = flashGame
        }
    }
}