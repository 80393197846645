import commonApi from '../../_EXT_API'
import { singleProxy } from '@/global/js/exportEnter'

class game extends commonApi {
    constructor(opt) {
        opt = opt || {}
        let localKey = 'mindGame'
        let name = 'mindGame'
        opt = { localKey, name }
        super(opt)
    }
    // 获取游戏类目
    getJoyGroup(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            data: {
                ...opt,
            },
        }
        return this.requestAction('getJoyGroup', options)
    }
    // 获取游戏列表
    getJoyList(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            data: {
                ...opt,
            },
        }
        return this.requestAction('getJoyList', options)
    }
    // 获取游戏内容
    getJoy(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt,
            },
        }
        return this.requestAction('getJoy', options)
    }
    // 获取用户游戏列表
    getExerciseList(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            data: {
                ...opt,
            },
        }
        return this.requestAction('getExerciseList', options)
    }
    // 提交用户游戏结果
    postGameResult(opt) {
        opt = opt || {}
        let { user_code, school_id, noLoad } = opt
        school_id = school_id || this.PARAMS.school_id
        noLoad = noLoad || false
        let options = {
            method: 'post',
            user_code,
            noLoad,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
            data: {
                ...opt,
                schoolId: school_id,
            },
            params: {
                school_id,
            }
        }
        return this.requestAction('postGameResult', options)
    }
    // 查询习题接口
    getJoyExercise(opt) {
        opt = opt || {}
        let {joy_id} = opt
        if(!joy_id) return
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt,
            },
        }
        return this.requestAction('getJoyExercise', options)
    }
    // 查询数独习题接口
    getJoyNoExercise(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt,
            },
        }
        return this.requestAction('getJoyNoExercise', options)
    }
    // 查询口算达人历史记录
    getRecord(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt,
            },
        }
        return this.requestAction('getRecord', options)
    }
    // 查询舒尔特历史记录
    getSchulteRecord(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt,
            },
        }
        return this.requestAction('getSchulteRecord', options)
    }
    // 查询超强记忆力历史记录
    getMemoryRecord(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt,
            },
        }
        return this.requestAction('getMemoryRecord', options)
    }
    // 查询错题
    getRecordMistakes(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt,
            },
        }
        return this.requestAction('getRecordMistakes', options)
    }
    // 查询排行榜
    getRankList(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt,
            },
        }
        return this.requestAction('getRankList', options)
    }
    // 更新用户游戏结果
    updateGameResult(opt) {
        opt = opt || {}
        let { school_id, noLoad, answer_id } = opt
        school_id = school_id || this.PARAMS.school_id
        noLoad = noLoad || false
        let options = {
            method: 'post',
            noLoad,
            answer_id,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
            data: {
                ...opt,
            },
            params: {
                school_id,
                answer_id,
            }
        }
        return this.requestAction('updateGameResult', options)
    }
    // 查询上榜奖励
    getRankAwardList(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt,
            },
        }
        return this.requestAction('getRankAwardList', options)
    }
    // 领取上榜奖励
    postRankAward(opt) {
        opt = opt || {}
        let options = {
            method: 'post',
            ...opt,
            data: {
                ...opt,
            },
        }
        return this.requestAction('postRankAward', options)
    }
    // 查询是否有未领取奖励
    getAwardStatus(opt){
        opt = opt || {}
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt,
            },
        }
        return this.requestAction('getAwardStatus', options)
    }
    // 防沉迷
    async postScreenTime(opt){
        opt = opt || {}
        let options = {
            method: 'post',
            ...opt,
            noError: true,
            fullData: true,
            data: {
                ...opt,
            },
        }
        let res = await this.requestAction('postScreenTime', options)
        return res
    }
    // 打点上报
    async getUserPoint(opt){
        opt = opt || {}
        let {user_code} = opt
        if(!user_code) return
        let options = {
            method: 'post',
            ...opt,
            noError: true,
            noLoad: true,
            fullData: true,
            data: {
                ...opt,
            }
        }
        let res = await this.requestAction('getUserPoint', options)
        return res
    }
    // 记录每个游戏数据
    async getRecordGame(opt){
        opt = opt || {}
        let {user_code} = opt
        if(!user_code) return
        let options = {
            method: 'post',
            ...opt,
            noError: true,
            noLoad: true,
            data: {
                ...opt,
            },
        }
        let res = await this.requestAction('getRecordGame', options)
        return res
    }
    // 是否挑战赛期间
    getIsDuringChallenge(opt) {
        opt = opt || {}
        let {user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!user_code) return
        let options = {
            method: 'get',
            data: {
                user_code
            }
        }
        return this.requestAction('getIsDuringChallenge', options)
    }
    // 引导用户参与挑战赛
    getIsChallengePopup(opt){
        opt = opt || {}
        let options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
                ...opt
            }
        }
        return this.requestAction('getIsChallengePopup', options)
    }
    // 获取挑战赛目录列表
    getChallengeList(opt){
        opt = opt || {}
        let {user_code} = opt
        opt.user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!opt.user_code) return
        let options = {
            method: 'get',
            data: {
                ...opt
            }
        }
        return this.requestAction('getChallengeList', options)
    }
    // 获取挑战赛对手信息和题
    getChallengeInfo(opt){
        opt = opt || {}
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt
            }
        }
        return this.requestAction('getChallengeInfo', options)
    }
    // 提交挑战赛答案
    postChallengeAnswer(opt){
        opt = opt || {}
        let {user_code} = opt
        opt.user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!opt.user_code) return
        let options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            ...opt,
            data: {
                ...opt
            }
        }
        return this.requestAction('postChallengeAnswer', options)
    }
    // 能力雷达图
    getChallengeUserAbility(opt){
        opt = opt || {}
        let {user_code} = opt
        opt.user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!opt.user_code) return
        let options = {
            method: 'get',
            data: {
                ...opt
            }
        }
        return this.requestAction('getChallengeUserAbility', options)
    }
    // 挑战赛排行榜
    getChallengeUserRank(opt){
        opt = opt || {}
        let {user_code} = opt
        opt.user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!opt.user_code) return
        let options = {
            method: 'get',
            ...opt,
            data: {
                ...opt
            }
        }
        return this.requestAction('getChallengeUserRank', options)
    }
    // 排行榜点赞
    postChallengeLike(opt){
        opt = opt || {}
        let {user_code} = opt
        opt.user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!opt.user_code) return
        let options = {
            method: 'post',
            ...opt,
            data: {
                ...opt
            }
        }
        return this.requestAction('postChallengeLike', options)
    }
}

export default singleProxy(game)