
export default {
    'getJoyGroup': {
        url: '/town/joy/course/group',
        serve: 'social',
    },
    // 查询 游戏/游戏难度/游戏关卡
    'getJoyList': {
        url: '/town/joy/list',
        serve: 'social',
    },
    'getJoy': {
        url: '/town/joy/{exercise_id}',
        serve: 'social',
    },
    'getExerciseList': {
        url: '/town/joy/user/exercise/list',
        serve: 'social',
    },
    // 上传习题
    'postGameResult': {
        url: '/town/joy/user/exercise/{user_code}',
        serve: 'social',
    },
    // 查询习题接口
    'getJoyExercise': {
        url: '/town/joy/exercise/{joy_id}',
        serve: 'social',
    },
    // 查询数独习题接口
    'getJoyNoExercise': {
        url: '/town/joy/exercise/no/{joy_id}',
        serve: 'social',
    },
    // 查询历史记录
    'getRecord':{
        url: '/town/joy/user/record/{user_code}',
        serve: 'social',
    },
    // 查询口算达人历史记录
    'getSchulteRecord': {
        url: '/town/joy/user/answer/{joy_id}',
        serve: 'social',
    },
    // 查询超强记录力历史记录
    'getMemoryRecord': {
        url: '/town/joy/user/memory/rank/{user_code}',
        serve: 'social',
    },
    // 查询错题
    'getRecordMistakes': {
        url: '/town/joy/user/record/detail/{record_id}',
        serve: 'social',
    },
    // 纠正错题
    'updateGameResult': {
        url: '/town/joy/user/exercise/update/{answer_id}',
        serve: 'social',
    },
    // 查询排行榜
    'getRankList': {
        url: '/town/joy/user/rank/{user_code}',
        serve: 'social',
    },
    // 查询上榜奖励
    'getRankAwardList': {
        url: '/town/joy/user/rank/award/{user_code}',
        serve: 'social',
    } ,
    // 领取上榜奖励
    'postRankAward': {
        url: '/town/joy/user/rank/award/{user_code}',
        serve: 'social',
    },
    // 查询是否有未领取奖励
    'getAwardStatus': {
        url: '/town/joy/user/rank/award/status/{user_code}',
        serve: 'social',
    },
    // 防沉迷
    'postScreenTime': {
        url: '/town/joy/user/heartbeat',
        serve: 'social',
    },
    // 打点上报
    'getUserPoint': {
        url: '/town/dashboard/user/point',
        serve: 'social',
    },
    // 记录每个游戏数据
    'getRecordGame': {
        url: '/town/dashboard/user/point/details',
        serve: 'social',
    },
    // 是否挑战赛期间
    'getIsDuringChallenge': {
        url: '/town/joy/challenge/status',
        serve: 'social',
    },
    // 引导用户参与挑战赛
    'getIsChallengePopup': {
        url: '/town/joy/challenge/popup',
        serve: 'social',
    },
    // 获取挑战赛目录列表
    'getChallengeList': {
        url: '/town/joy/challenge/list',
        serve: 'social',
    },
    // 获取对手信息和题
    'getChallengeInfo': {
        url: '/town/joy/challenge/info',
        serve: 'social',
    },
    // 提交答案
    'postChallengeAnswer': {
        url: '/town/joy/challenge/user/answer',
        serve: 'social',
    },
    // 能力雷达图
    'getChallengeUserAbility': {
        url: '/town/joy/challenge/user/ability',
        serve: 'social',
    },
    // 挑战赛排行榜
    'getChallengeUserRank': {
        url: '/town/joy/challenge/user/rank',
        serve: 'social',
    },
    // 排行榜点赞
    'postChallengeLike': {
        url: '/town/joy/challenge/like/{like_user_code}',
        serve: 'social',
    }
}