export default {
    state: {
        imageList: {
            blob: {}
        }
    },
    mutations: {
        imageCacheSetImage(state, opt){
            const {url, data, type} = opt || {}
            let {imageCache} = state
            imageCache = imageCache || {}
            let {imageList} = imageCache
            imageList = imageList || {}
            const list = imageList[type] || {}
            list[url] = data
            imageList[type] = list
            imageCache.imageList = imageList
            state.imageCache = imageCache
        }
    }
}