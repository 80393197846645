<template>
    <div class="mind-game-screen-time" v-if="show && pathShow">
        <div class="bcg bold">
            <div class="off" @click="close"></div>
            <div class="txt-box title" v-if="titleTxt">
                <div class="txt">{{titleTxt}}</div>
            </div>
            <div class="txt-box" v-else>
                <div class="txt">您今天已经累计玩了{{timeTxt}}分钟</div>
                <div class="txt">休息一下～明天再来吧～</div>
            </div>
        </div>
    </div>
</template>
<script>
import musicSetting from '@/pages/mind/global/js/musicSetting.js'
export default {
    name: '',
    props: {},
    data() {
        return {
            musicSetting: new musicSetting(),
            show: false,
            timeTxt: '40',
            titleTxt: '',
            routeNameObj: {
                '/game/challenge': 'game-challenge', //挑战赛
                '/game': 'game-list',
            }
        };
    },
    methods: {
        open(val) {
            val = val || {}
            if(val.timeTxt) this.timeTxt = val.timeTxt
            if(val.titleTxt) this.titleTxt = val.titleTxt
            this.show = true
        },
        close(){
            this.musicSetting.soundOpen('click','', true)
            let routeName = 'Home' // 思维乐园:game、学霸殿堂:palace、其它:other
            let pathMap = this.routeNameObj
                const keys = Object.keys(pathMap);
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    if (this.$route.path.indexOf(key) > -1) {
                        routeName = pathMap[key];
                        break;
                    }
                }
            this.backHome(routeName)
            this.show = false
            this.titleTxt = ''
        },
        async backHome(routeName){
            let name = routeName ? routeName : 'Home'
            let type = 'replace'
            this._routeHand.goto({name, type})
        },
    },
    created() { 
    },
    components: {},
    computed: {
        pathShow(){
            let path = this.$route.path
            return path.indexOf('/game/') > -1 ? true : false
        }
    },
    watch: {},
    mounted() { },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
    .mind-game-screen-time{
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 111;
        .bcg{
            .background-img-max(url('@{assetsUrlMind}/img/game/g37.png'));
            width: 330px;
            height: 201px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            .off{
                .background-img-max(url('@{assetsUrlMind}/img/game/g38.png'));
                position: absolute;
                width: 17px;
                height: 17px;
                right: -3px;
                top: 58px;
            }
            .txt-box{
                width: 100%;
                position: absolute;
                top: 113px;
                left: 0;
                right: 0;
                margin: 0 auto;
                line-height: 23px;
                height: 37px;
                &.title{
                    padding: 0 20px;
                    >.txt{
                        white-space: wrap;
                    }
                }
            }
            .txt{
                text-align: center;
                font-size: 15px;
                font-weight: bold;
                color: #FFB12B;
                white-space: nowrap;
            }
        }
    }
</style>