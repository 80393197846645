import data from './base'

let key = 'mind'
let list = data.schoolList
let schoolId = null
for(let i in list){
    if(key == list[i].key){
        schoolId = list[i].id
        break
    }
}

export default {
    ...data,
    base_url: 'mind.html',
    school_id: schoolId,
    assetsUrlMind: 'https://assets.19x19.com/gaotu/mind',
    appDataKey: 'app_mind',
    difficulty_list: {
        '1': '容易',
        '2': '较易',
        '3': '中等',
        '4': '较难',
        '5': '困难',
    },
    exercise_type_list: {
        'course': {
            title: '作业',
            fun: 'courseHandler',
            titleHandler: 'courseTitle'
        },
        'puzzle': {
            title: '闯关',
            fun: 'puzzleHandler'
        },
        'subject': {
            title: '专项',
            fun: 'subjectHandler'
        },
        'note': {
            title: '错题本',
            fun: 'noteHandler'
        },
        'palace': {
            title: '练习',
            fun: 'palaceHandler'
        },
        'island': {
            title: '海岛',
            fun: 'islandHandler'
        },
        'newNote': {
            title: '新版错题本',
            fun: 'newNoteHandler'
        },
        'answerNote': {
            title: '新版错题本解析',
            fun: 'answerNoteHandler'
        }
    },
    exercise_design_width: 800,
    avatar_room_bcg_preview_time: 60000, //个人形象房间背景预览时间
    currency: '思维币',
    course_expand_text: '随心练',
    flashGameDefineTypeKey: 'clientType',
    staticPaths: [
        'flashGame'
    ]
}